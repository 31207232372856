.CompactCard{
    display: flex;
    flex: 1;
    border-radius: 0.7rem;
    height: 7rem!important;
    color: white;
    position: relative;
    cursor: pointer;
    padding: 1rem;
}
.CompactCard:hover{
    box-shadow: none!important;
}

/* radialBar */
.radialbar{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
}
.CircularProgressbar{
    width: 4rem!important;
    overflow: visible;
}
.CircularProgressbar-path{
    stroke: white!important;
    stroke-width: 12px!important;
    filter: drop-shadow(2px 4px 6px white);
}
.CircularProgressbar-trail{
    display: none;
}
.CircularProgressbar-text{
    fill: white!important;
}
.radialBar>span{
    font-style: 17px;
    font-weight: bold;
}

/* detail */
.detail{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

/* expandable crard */
.expandedcard{
    position: absolute;
    width: 60%;
    height: 70vh;
    z-index: 9;
    left: 13rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}
.expandedcard >span:nth-of-type(1){
    color: white;
    font-size: 26px;
    font-weight: bold;
    text-shadow: 0px 0px 15px white;
}
.expandedcard >span:nth-of-type(2){
    color: rgb(223, 222, 222);
    font-size: 16px;
}
.chart-container{
    width: 70%;
}

@media screen and (max-width: 1200px) {
    .expandedcard{
        top: 2rem;
        height: 70vh;
        left: 6rem;
    }
}
@media screen and (max-width: 780px) {
    .expandedcard{
        top: 8rem;
        height: 45%;
        left: 15px;
        width: 80%;
    }
    .chart-container{
        width: 100%;
    }
}