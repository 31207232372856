.cards{
    display: flex;
    gap: 10px;
}
.parentContainer{
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .cards{
        flex-direction: column;
    }
    .parentContainer{
        height: 9rem;
    }
}
@media screen and (max-width: 780px) {
    .cards{
        width: 90%;
    }
}