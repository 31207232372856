.rightside{
    display: flex;
    flex-direction: column;
    width: 95%;
    justify-content: space-evenly;
}

@media screen and (max-width: 1200px) {
    .rightside{
        justify-content: start;
        margin-top: 3rem;
    }
}
@media screen and (max-width: 780px) {
    .rightside{
        width: 100%;
        margin-top: 0;
    }
    .rightside>div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}