.MuiTableRow-root>*{
    padding: 10px;
}
.MuiTableRow-root.MuiTableRow-head>*{
    font-weight: bold!important;
}

.table td, th{
    border: none!important;
}
.table:first-child{
    border-radius: 0.7rem!important;
}
.status{
    padding: 5px;
    border-radius: 9px;
}
.details{
    color: #00b5ff!important
}

@media screen and (max-width: 1200px) {
    .table{
        width: 170%;
        margin-top: 2rem;
    }
}
@media screen and (max-width: 780px) {
    .table{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 18rem;
    }
}