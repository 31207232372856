.maindash{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (max-width: 1200px) {
    .maindash{
        justify-content: flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 780px) {
    .maindash{
        align-items: center;
    }
}