.updates{
    width: 85%;
    background: white;
    border-radius: 0.7rem;
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}
.update{
    display: flex;
    gap: 0.5rem;
}
.update>img{
    width: 3.8em;
    height: 3.4rem;
}
.noti>div>span:nth-of-type(1){
    font-weight: bold;
}